import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PmsService {

  constructor(private http: HttpClient) { }

  private vendorSubject = new BehaviorSubject<any>(null);
  vendors$ = this.vendorSubject.asObservable();

  private productIdSubject = new BehaviorSubject<any>(null);
  productId$ = this.productIdSubject.asObservable();

  private productNameSubject = new BehaviorSubject<any>(null);
  productName$ = this.productNameSubject.asObservable();

  private syncVendorSubject = new BehaviorSubject<any>({
    percentage: 0,
    trip_xoxo_code: null,
    vendor_id: null,
    synced_products: [],
    sync_status: false
  });
  syncVendor$ = this.syncVendorSubject.asObservable();

  updateSyncVendors(data: any) {
    this.syncVendorSubject.next(data);
  }

  updateVendors(data: any) {
    this.vendorSubject.next(data);
  }

  updateProductId(id: any) {
    this.productIdSubject.next(id);
  }

  updateProductName(name: any) {
    this.productNameSubject.next(name);
  }

  getVendors(): Observable<any> {
    return this.http.get(`${environment.apiURL}/vendors`);
  }

  getProducts(pageno: number, pagesize: number, search?: string, vendor?: string, destination?: string, country?: string, status?: string): Observable<any> {
    let params = new HttpParams()
      .set('page', pageno.toString())
      .set('page_size', pagesize.toString());
    if (search) params = params.set('search_field', search);
    if (vendor) params = params.set('vendor_name', vendor);
    if (destination) params = params.set('destination_name', destination);
    if (country) params = params.set('country_name', country);
    if (status) params = params.set('status', status);
    return this.http.get(`${environment.apiURL}/products`, { params: params });
  }

  // setDescription(id, data): Observable<any> {
  //   return this.http.put(`${environment.apiURL}/cms-admin/country/country-description/${id}/`, data);
  // }

  syncProducts(page, data): Observable<any> {
    return this.http.post(`${environment.apiURL}/product-sync/?page=${page}`, data);
  }

  syncSingleProduct(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/single-vendor-product-sync`, data);
  }

  terminateSync(page, data): Observable<any> {
    return this.http.post(`${environment.apiURL}/terminate-sync/?page=${page}`, data);
  }

  resumeSync(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/resume-sync/`, data);
  }

  syncProductsNew(vendor?): Observable<any> {
    return this.http.get(`${environment.apiURL}/product-sync/${vendor ? '?vendor=' : ''}${vendor || ''}`);
  }

  syncViatorProducts(data, cursor?): Observable<any> {
    return this.http.post(`${environment.apiURL}/product-sync/${cursor ? '?nextCursor=' : ''}${cursor || ''}`, data);
  }

  getProductBanners(product): Observable<any> {
    return this.http.get(`${environment.apiURL}/product_banner?product=${product}`);
  }

  deleteProductBanner(id): Observable<any> {
    return this.http.delete(`${environment.apiURL}/product_banner/${id}`);
  }

  setBannerPrimary(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/banner_isprimary`, data);
  }

  postProductBanners(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/product_banner`, data);
  }

  editProductBanners(id, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/product_banner/${id}`, data);
  }

  getProductById(id): Observable<any> {
    return this.http.get(`${environment.apiURL}/products/${id}`);
  }

  deleteProductDescription(id): Observable<any> {
    return this.http.delete(`${environment.apiURL}/product_description/${id}`);
  }

  postProduct(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/products`, data);
  }

  editProduct(id, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/products/${id}`, data);
  }

  getSEO(product): Observable<any> {
    return this.http.get(`${environment.apiURL}/product-seo/?product=${product}`);
  }

  postSEO(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/product-seo/`, data);
  }

  editSEO(id, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/product-seo/${id}/`, data);
  }

  getTourDetails(product): Observable<any> {
    return this.http.get(`${environment.apiURL}/tour-details?product=${product}`);
  }

  postTourDetails(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/tour-details`, data);
  }

  updateTourDetails(id, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/tour-details/${id}`, data);
  }

  getCountryList() {
    return this.http.get(`${environment.apiURL}/country`);
  }

  getCustomerProfiling(product) {
    return this.http.get(`${environment.apiURL}/customer-profile/${product}`);
  }

  getTicketDetails(product) {
    return this.http.get(`${environment.apiURL}/ticket-details/${product}`);
  }

  postCustomerProfiling(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/customer-profile`, data);
  }

  postTicketDetails(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/ticket-details`, data);
  }

  getTicketType(product, type, query_type?, page?, page_size?, customer_profile?, ticket?, season?, start_date?, end_date?, weekday?, timeslot?) {
    let params = new HttpParams().set('ticket_type', type);
    if (query_type !== undefined && query_type !== null) params = params.set('query_type', query_type);
    if (page !== undefined && page !== null) params = params.set('page', page);
    if (page_size !== undefined && page_size !== null) params = params.set('page_size', page_size);
    if (customer_profile !== undefined && customer_profile !== null) params = params.set('customer_profile_id', customer_profile);
    if (ticket !== undefined && ticket !== null) params = params.set('ticket_id', ticket);
    if (season !== undefined && season !== null) params = params.set('season', season);
    if (start_date !== undefined && start_date !== null) params = params.set('start_date', start_date);
    if (end_date !== undefined && end_date !== null) params = params.set('end_date', end_date);
    if (weekday !== undefined && weekday !== null) params = params.set('weekday', weekday);
    if (timeslot !== undefined && timeslot !== null) params = params.set('time_slot', timeslot);
    return this.http.get(`${environment.apiURL}/admin-price-details/${product}`, { params });
  }

  postTicketType(product) {
    return this.http.post(`${environment.apiURL}/admin-price-details/`, product);
  }

  updateTicketType(product) {
    return this.http.put(`${environment.apiURL}/admin-price-details/`, product);
  }

  getCategories(product) {
    return this.http.get(`${environment.apiURL}/product_categories?product=${product}`);
  }

  assignCategory(product, categories) {
    return this.http.post(`${environment.apiURL}/product_categories?product=${product}`, categories);
  }

  addCategory(category) {
    return this.http.post(`${environment.apiURL}/category`, category);
  }

  editCategory(id, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/category/${id}`, data);
  }

  getUnavailableDates(product) {
    return this.http.get(`${environment.apiURL}/non_availabledates?product_id=${product}`);
  }

  setUnavailableDates(data) {
    return this.http.post(`${environment.apiURL}/non_availabledates`, data);
  }

  getAvailableSeasons(product) {
    return this.http.get(`${environment.apiURL}/product_season?product_id=${product}`);
  }

  addAvailableSeasons(data) {
    return this.http.post(`${environment.apiURL}/product_season`, data);
  }

  editAvailableSeasons(id, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/product_season/${id}`, data);
  }

  getAvailableWeeks(product) {
    return this.http.get(`${environment.apiURL}/availableweeks?product_id=${product}`);
  }

  setAvailableDates(data) {
    return this.http.post(`${environment.apiURL}/availabledates`, data);
  }

  getTimeSlot(product) {
    return this.http.get(`${environment.apiURL}/available_timeslot?product_id=${product}`);
  }

  setTimeSlot(data) {
    return this.http.post(`${environment.apiURL}/available_timeslot`, data);
  }

  setAvailableWeeks(data) {
    return this.http.post(`${environment.apiURL}/availableweeks`, data);
  }

  getTicketTypeProduct(product) {
    return this.http.get(`${environment.apiURL}/ticket_type?product_id=${product}`);
  }

  setTicketTypeProduct(data) {
    return this.http.post(`${environment.apiURL}/ticket_type`, data);
  }

  postClubPass(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/clubpass`, data);
  }

  updateClubPass(id, data): Observable<any> {
    return this.http.patch(`${environment.apiURL}/clubpass/${id}`, data);
  }

  getRatingsReviews(id, pagesize, pageno): Observable<any> {
    return this.http.get(`${environment.apiURL}/product_rating/review?product_id=${id}&page=${pagesize}&page_size=${pageno}`);
  }

  activeRatingsReviews(data) {
    return this.http.post(`${environment.apiURL}/hide-review`, data);
  }

  getAttributes(): Observable<any> {
    return this.http.get(`${environment.apiURL}/attribute`);
  }

  getTagList(product) {
    return this.http.get(`${environment.apiURL}/tag_suggestions?product_id=${product}`);
  }

  getDestinations(search?: string): Observable<any> {
    return this.http.get(`${environment.apiURL}/destination_dropdown?search_field=${search || ''}`);
  }

  getCountries(search?: string): Observable<any> {
    return this.http.get(`${environment.apiURL}/country_dropdown?search_field=${search || ''}`);
  }

  changeBannerPosition(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/banner_positon`, data);
  }

  getTaxDetails(product) {
    return this.http.get(`${environment.apiURL}/tax_details?product_id=${product}`);
  }

  setTaxDetails(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/tax_details`, data);
  }

  getGeneratedSchema(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/generate_schema`, data);
  }

  getConfigureColumns(): Observable<any> {
    return this.http.get(`${environment.apiURL}/column-configure/`);
  }

  setConfigureColumns(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/column-configure/`, data);
  }

  setBestSellerProduct(data) {
    return this.http.post(`${environment.apiURL}/best_seller`, data);
  }

  setDefaultStatus(data) {
    return this.http.post(`${environment.apiURL}/description-isdefault`, data);
  }

  setVisibleStatus(data) {
    return this.http.post(`${environment.apiURL}/description_show`, data);
  }

  getSuppliers(): Observable<any> {
    return this.http.get(`${environment.apiURL}/supplier-dropdown`);
  }
  // Get Product Icon======================================================================

  getProductIcon(productId: any): Observable<any> {
    return this.http.get(`${environment.apiURL}/product_icon?product=${productId}`);
  }

  // Add Product Icon====================================================================

  addProductIcon(requestBody: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/product_icon`, requestBody);
  }
  deleteProductIcon(productId: any): Observable<any> {
    return this.http.delete(`${environment.apiURL}/product_icon/${productId}`);
  }

  getCustomPriceList(productId, pagesize, pageno): Observable<any> {
    return this.http.get(`${environment.apiURL}/custom-price?product_id=${productId}&page=${pagesize}&page_size=${pageno}`);
  }

  setCustomPriceList(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/custom-price`, data);
  }

  changePriceListStatus(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/enable_customprice`, data);
  }

  changeTicketPosition(data, url): Observable<any> {
    return this.http.post(`${environment.apiURL}/${url}`, data);
  }

  // Export Product ========================================================================

  exportProductData(data): Observable<Blob> {
    return this.http.post(`${environment.apiURL}/export-product-data`, data, { responseType: 'blob' });
  }
}
